<template>
  <div class="table-container">
    <div class="table-headers pa-4 elevation-2">
      <v-row>
        <v-col sm="6" md="3" cols="12">
          <v-text-field
            label="Search by email"
            outlined
            dense
            hide-details="auto"
            v-model="searchEmail"
          ></v-text-field>
        </v-col>
        <v-col sm="6" md="3" cols="12">
          <v-select
            :items="['Active', 'Inactive']"
            label="Status"
            outlined
            dense
            clearable
            hide-details="auto"
            v-model="filterStatus"
          ></v-select>
        </v-col>
        <v-spacer></v-spacer>
        <v-col md="3" sm="6" cols="12" class="table-header-buttons">
          <v-btn
            color="var(--primary)"
            dark
            dense
            :disabled="!isSuperAdmin"
            class="primary-btn-inner"
            @click="
              manageOperatorAction = 'Create'
              showManageOperatorDialog = true
            "
            >Create Operator
          </v-btn>
        </v-col>
      </v-row>
    </div>
    <v-data-table
      :headers="headers"
      :items="filteredList"
      :items-per-page="10"
      class="elevation-2"
      :loading="loading"
      loading-text="Loading admin list..."
    >
      <template v-slot:[`item.is_active`]="{ item }">
        <span v-if="item.is_active">Active</span>
        <span v-else>Inactive</span>
      </template>
      <template v-slot:[`item.operation`]="{ item }">
        <v-menu offset-y>
          <template v-slot:activator="{ attrs, on }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>

          <v-list>
            <v-list-item link>
              <v-list-item-title @click="listAction('Edit', item)"
                >Edit</v-list-item-title
              >
            </v-list-item>
            <v-list-item v-if="!item.is_active" link>
              <v-list-item-title @click="listAction('Activate', item)"
                >Activate</v-list-item-title
              >
            </v-list-item>
            <v-list-item v-else link>
              <v-list-item-title @click="listAction('Deactivate', item)"
                >Deactivate</v-list-item-title
              >
            </v-list-item>
            <v-list-item link>
              <v-list-item-title @click="listAction('Reset Password', item)"
                >Reset Password</v-list-item-title
              >
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </v-data-table>
    <ManageOperatorDialog
      :dialog.sync="showManageOperatorDialog"
      :action="manageOperatorAction"
      :operator="selectedOperator"
      @refresh="getAdminList()"
    />
    <ActivateDeactivateOperatorDialog
      :dialog.sync="showActivateDeactivateDialog"
      :action="manageOperatorAction"
      :operator="selectedOperator"
      @refresh="getAdminList()"
    />
    <ResetOperatorPasswordDialog
      :dialog.sync="showResetOperatorPasswordDialog"
      :operator="selectedOperator"
      @refresh="getAdminList()"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
const ManageOperatorDialog = () =>
  import('@/components/Dialog/ManageOperatorsDialog.vue')
const ActivateDeactivateOperatorDialog = () =>
  import('@/components/Dialog/activateDeactivateOperatorDialog.vue')
const ResetOperatorPasswordDialog = () =>
  import('@/components/Dialog/resetOperatorPasswordDialog.vue')

export default {
  components: {
    ManageOperatorDialog,
    ActivateDeactivateOperatorDialog,
    ResetOperatorPasswordDialog,
  },
  data: () => ({
    headers: [
      {
        text: 'Employee Name',
        align: 'start',
        sortable: true,
        value: 'name',
      },
      {
        text: 'Email ID',
        align: 'start',
        sortable: true,
        value: 'email',
      },
      {
        text: 'User ID',
        align: 'start',
        sortable: true,
        value: 'user_id',
      },
      {
        text: 'Phone number',
        align: 'start',
        sortable: false,
        value: 'phone_number',
      },
      {
        text: 'Department',
        align: 'start',
        sortable: false,
        value: 'dapartment',
      },
      {
        text: 'Address',
        align: 'start',
        sortable: false,
        value: 'address',
      },
      {
        text: 'Status',
        align: 'start',
        sortable: false,
        value: 'is_active',
      },
      {
        text: '',
        align: 'start',
        sortable: false,
        value: 'operation',
      },
    ],
    searchEmail: '',
    filterStatus: '',
    showManageOperatorDialog: false,
    showActivateDeactivateDialog: false,
    showResetOperatorPasswordDialog: false,
    manageOperatorAction: '',
    selectedOperator: {},
    loading: false,
  }),
  computed: {
    ...mapGetters(['isSuperAdmin', 'adminList']),
    filteredList() {
      let filteredAdminList = []
      if (this.searchEmail !== '') {
        filteredAdminList = this.adminList.filter((admin) => {
          return admin.email.match(this.searchEmail)
        })
      } else {
        filteredAdminList = this.adminList
      }
      if (this.filterStatus !== '') {
        if (this.filterStatus === 'Active') {
          filteredAdminList = filteredAdminList.filter(
            (admin) => admin.is_active === true
          )
        } else if (this.filterStatus === 'Inactive') {
          filteredAdminList = filteredAdminList.filter(
            (admin) => admin.is_active === false
          )
        }
      }
      return filteredAdminList
    },
  },
  methods: {
    async getAdminList() {
      this.loading = true
      try {
        await this.$store.dispatch('get_admin_list')
      } catch (error) {
        this.$root.snackbar.show({
          message: error.response.data.message,
          color: 'error',
        })
      } finally {
        this.loading = false
      }
    },
    listAction(action, selectedOperator) {
      if (action === 'Edit') {
        this.selectedOperator = selectedOperator
        this.manageOperatorAction = 'Edit'
        this.showManageOperatorDialog = true
      } else if (action === 'Reset Password') {
        this.showResetOperatorPasswordDialog = true
        this.selectedOperator = selectedOperator
      } else {
        this.showActivateDeactivateDialog = true
        this.manageOperatorAction = action
        this.selectedOperator = selectedOperator
      }
    },
  },
  mounted() {
    this.getAdminList()
  },
}
</script>

<style lang="scss" scoped>
.row {
  align-items: center;
}

.table-headers {
  display: flex;
  flex-direction: column;

  .table-header-buttons {
    display: flex;
    flex-direction: column;
    align-items: center;

    .v-btn {
      width: 100%;
      margin-bottom: 0.5rem;
    }

    @media screen and (min-width: 768px) {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;

      .v-btn {
        flex: 1;
      }
    }

    @media screen and (min-width: 971px) {
      .v-btn {
        flex: none;
      }
    }
  }
}
</style>