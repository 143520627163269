var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"table-container"},[_c('div',{staticClass:"table-headers pa-4 elevation-2"},[_c('v-row',[_c('v-col',{attrs:{"sm":"6","md":"3","cols":"12"}},[_c('v-text-field',{attrs:{"label":"Search by email","outlined":"","dense":"","hide-details":"auto"},model:{value:(_vm.searchEmail),callback:function ($$v) {_vm.searchEmail=$$v},expression:"searchEmail"}})],1),_c('v-col',{attrs:{"sm":"6","md":"3","cols":"12"}},[_c('v-select',{attrs:{"items":['Active', 'Inactive'],"label":"Status","outlined":"","dense":"","clearable":"","hide-details":"auto"},model:{value:(_vm.filterStatus),callback:function ($$v) {_vm.filterStatus=$$v},expression:"filterStatus"}})],1),_c('v-spacer'),_c('v-col',{staticClass:"table-header-buttons",attrs:{"md":"3","sm":"6","cols":"12"}},[_c('v-btn',{staticClass:"primary-btn-inner",attrs:{"color":"var(--primary)","dark":"","dense":"","disabled":!_vm.isSuperAdmin},on:{"click":function($event){_vm.manageOperatorAction = 'Create'
            _vm.showManageOperatorDialog = true}}},[_vm._v("Create Operator ")])],1)],1)],1),_c('v-data-table',{staticClass:"elevation-2",attrs:{"headers":_vm.headers,"items":_vm.filteredList,"items-per-page":10,"loading":_vm.loading,"loading-text":"Loading admin list..."},scopedSlots:_vm._u([{key:"item.is_active",fn:function(ref){
            var item = ref.item;
return [(item.is_active)?_c('span',[_vm._v("Active")]):_c('span',[_vm._v("Inactive")])]}},{key:"item.operation",fn:function(ref){
            var item = ref.item;
return [_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var attrs = ref.attrs;
            var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{attrs:{"link":""}},[_c('v-list-item-title',{on:{"click":function($event){return _vm.listAction('Edit', item)}}},[_vm._v("Edit")])],1),(!item.is_active)?_c('v-list-item',{attrs:{"link":""}},[_c('v-list-item-title',{on:{"click":function($event){return _vm.listAction('Activate', item)}}},[_vm._v("Activate")])],1):_c('v-list-item',{attrs:{"link":""}},[_c('v-list-item-title',{on:{"click":function($event){return _vm.listAction('Deactivate', item)}}},[_vm._v("Deactivate")])],1),_c('v-list-item',{attrs:{"link":""}},[_c('v-list-item-title',{on:{"click":function($event){return _vm.listAction('Reset Password', item)}}},[_vm._v("Reset Password")])],1)],1)],1)]}}],null,true)}),_c('ManageOperatorDialog',{attrs:{"dialog":_vm.showManageOperatorDialog,"action":_vm.manageOperatorAction,"operator":_vm.selectedOperator},on:{"update:dialog":function($event){_vm.showManageOperatorDialog=$event},"refresh":function($event){return _vm.getAdminList()}}}),_c('ActivateDeactivateOperatorDialog',{attrs:{"dialog":_vm.showActivateDeactivateDialog,"action":_vm.manageOperatorAction,"operator":_vm.selectedOperator},on:{"update:dialog":function($event){_vm.showActivateDeactivateDialog=$event},"refresh":function($event){return _vm.getAdminList()}}}),_c('ResetOperatorPasswordDialog',{attrs:{"dialog":_vm.showResetOperatorPasswordDialog,"operator":_vm.selectedOperator},on:{"update:dialog":function($event){_vm.showResetOperatorPasswordDialog=$event},"refresh":function($event){return _vm.getAdminList()}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }